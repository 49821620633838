// const mainHeader = document.querySelector('.main-header')
// const menuButton = mainHeader.querySelector('.main-header__menu-btn')
// let firstFocusableElement
// let lastFocusableElement

// menuButton.onclick = () => {
//     if (!mainHeader.classList.contains('main-header--menu-open')) {
//         openHeaderMenu()
//     } else {
//         closeHeaderMenu()
//     }
// }

// function openHeaderMenu() {
//     mainHeader.classList.add('main-header--menu-open')
//     document.body.style.overflow = 'hidden'

//     // Detect first and last focusable elements
//     const focusableElements = mainHeader.querySelectorAll(
//         'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
//     )

//     firstFocusableElement = focusableElements[0]
//     lastFocusableElement = focusableElements[focusableElements.length - 1]
//     // Add event listeners to trap focus inside mainHeader
//     firstFocusableElement.addEventListener('keydown', trapHeaderFocus)
//     lastFocusableElement.addEventListener('keydown', trapHeaderFocus)
// }

// function closeHeaderMenu() {
//     mainHeader.classList.remove('main-header--menu-open')
//     document.body.style.overflow = ''

//     // Remove event listeners when the menu is closed
//     firstFocusableElement.removeEventListener('keydown', trapHeaderFocus)
//     lastFocusableElement.removeEventListener('keydown', trapHeaderFocus)
// }

// function trapHeaderFocus(event) {
//     const isTabPressed = event.key === 'Tab' || event.keyCode === 9
//     if (!isTabPressed) return

//     if (event.shiftKey) {
//         // If shift key is pressed, focus the last focusable element
//         if (document.activeElement === firstFocusableElement) {
//             event.preventDefault()
//             lastFocusableElement.focus()
//         }
//     } else {
//         // If shift key is not pressed, focus the first focusable element
//         if (document.activeElement === lastFocusableElement) {
//             event.preventDefault()
//             firstFocusableElement.focus()
//         }
//     }
// }

// // Close 
// function closeHeaderOnEsc(event) {
//     const isEscPressed = event.key === 'Escape' || event.keyCode === 27
//     if (isEscPressed && mainHeader.classList.contains('main-header--menu-open')) {
//         closeHeaderMenu()
//     }
// }

// // Event listener to the document to handle the ESC key press
// document.addEventListener('keydown', closeHeaderOnEsc)