document.addEventListener('DOMContentLoaded', () => {
   const handleTooltip = () => {
      const tooltipTriggers = document.querySelectorAll('[data-bs-toggle="tooltip"]')

      tooltipTriggers.forEach(trigger => {
         const handleClickOutside = clickOutsideEvent => {
            const isClickInside = clickOutsideEvent.target.closest('.tooltip')

            if (!isClickInside) {
               const tooltipObj = bootstrap.Tooltip.getOrCreateInstance(trigger)   
               tooltipObj.hide()
            }
         }

         const handleEscPress = keyEvent => {
            if (keyEvent.key === 'Escape') {
               const tooltipObj = bootstrap.Tooltip.getOrCreateInstance(trigger)   
               tooltipObj.hide()
            }
         }

         const handleShown = tooltipOpenEvent => {
            if (tooltipOpenEvent.srcElement !== trigger) return

            document.addEventListener('click', handleClickOutside)
            document.addEventListener('keydown', handleEscPress)
         }

         const handleHidden = () => {
            document.removeEventListener('click', handleClickOutside)
            document.removeEventListener('click', handleEscPress)
         }

         document.addEventListener('shown.bs.tooltip', handleShown)
         document.addEventListener('hidden.bs.tooltip', handleHidden)
      })
   }

   handleTooltip()
})