window.onload = function () {
    const hubspotForms = document.querySelectorAll('.hubspot-form')
    if (hubspotForms != null && hubspotForms.length > 0) {
        handleHubspotSubmit()
        // Add form classes to the embeded Hubspot form
        hubspotForms.forEach(hubspotForm => {

            hubspotForm.onclick = () => {
                // check if there is any "file" input
                const fileInputs = hubspotForm.querySelectorAll('.hs-fieldtype-file')
                if (fileInputs != null && fileInputs.length > 0 && !hubspotForm.classList.contains('file-input-ready')) {
                    fileInputs.forEach(file => {
                        if(!hubspotForm.classList.contains('file-input-ready')){
                            // Create the file list
                            const fileList = document.createElement('ul');
                            fileList.classList.add('file-list')
                            file.appendChild(fileList);
                            
                            // file input functionalities (file-upload.js)
                            handleFileInput(file)
                        }
                    })

                    hubspotForm.classList.add('file-input-ready')
                }
            }
        })
    }
}

function handleHubspotSubmit() {
    window.addEventListener('message', event => {        
        if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
            const query = `hs-form-${event.data.id}`
            const message = document.querySelector(`.${query}`)
            const form = message.closest('.hubspot-form')
            
            if(form){
                form.scrollIntoView({ behavior: 'smooth' })
            }
        }
    })
}
