document.addEventListener('DOMContentLoaded', () => {
   const enableTooltip = () => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
      const tooltipList = [...tooltipTriggerList].map(element => {
         element.setAttribute("tabindex", "0")

         function findBackgroundColorUpwards(targetElement, limitElement) {
            if (
               targetElement === limitElement
               || window.getComputedStyle(targetElement).backgroundColor !== "rgba(0, 0, 0, 0)"
               || !targetElement.parentElement
            ){
               const lastBackgroundColor = window.getComputedStyle(targetElement).backgroundColor 
               return lastBackgroundColor
            }

            return findBackgroundColorUpwards(targetElement.parentElement, limitElement)
         }


         function checkBackgroundColors (lastValidBackgroundColor) {
            const lightGreen = "rgb(217, 255, 227)"
            const darkGreen = "rgb(0, 52, 46)"

            if (lastValidBackgroundColor === lightGreen) {
               element.classList.add("dark")
               return "dark"
            } else if (lastValidBackgroundColor === darkGreen) {
               element.classList.add("darker")
               return "darker"
            } else {
               element.classList.add("light")
               return "light"
            }
         }

         const bgColor = () => {
            const upwardsBackgroundColor = findBackgroundColorUpwards(element, document.querySelector("main"))
            
            if (checkBackgroundColors(upwardsBackgroundColor) === "light") {
               return "bg-light-green"
            } else if (checkBackgroundColors(upwardsBackgroundColor) === "dark") {
               return "bg-light-cool-green"
            } else if (checkBackgroundColors(upwardsBackgroundColor) === "darker"){
               return "bg-dark-green"
            }
         }


         const content = document.createElement('div')
         content.classList.add("tooltip-content")
         content.classList.add(bgColor())

         const title = element.dataset.tooltipTitle
         const description = element.dataset.tooltipDescription
         const closeText = element.dataset.tooltipCloseText || "Close"

         content.innerHTML = `
            <div class="text">
               <div class="title">${title}</div>
               <div class="description">${description}</div>
            </div>
            <div class="close">
               <div class="icon"><span class="sr-only">Close Icon</span></div>
               <div>${closeText}</div>
            </div>
         `

         const tooltipObj = new bootstrap.Tooltip(element,
            {
               trigger: 'click',
               html: true,
               placement: 'bottom',
               title: content,
            }
         )

         element.addEventListener('shown.bs.tooltip', () => {
            const closeButton = document.querySelector('.tooltip .close')
            closeButton.addEventListener('click', () => tooltipObj.hide())
         })
      })
   }

   enableTooltip()
})